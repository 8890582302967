import { fonts, fontFaces } from './fonts';
import { shadows } from './shadows';
import { typographyVariants as typography } from './typography';
import { colors } from '../../tokens/loveholidays-old/colors';
import { ThemeDefinition } from '../types';
import {
  breakpointSizes,
  incrementSizes as sizes,
  motion,
  borders as borderWidths,
  radii,
  spacerSizes as space,
  zIndices,
} from '@Tokens';

export const theme: ThemeDefinition = {
  useCustomProperties: false,
  breakpoints: [
    '768px',
    '1200px',
  ],
  breakpointSizes,
  radii,
  borderWidths,
  colors,
  fonts,
  fontFaces,
  shadows,
  space,
  sizes,
  typography,
  motion,
  zIndices,
};
